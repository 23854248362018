.container {
  background: linear-gradient(180deg, #030405 0%, #120101 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  gap: 1.5rem;
}

.container > h3 {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.60225rem; /* 106.818% */
  letter-spacing: 0.10681rem;
  text-transform: uppercase;
  background: linear-gradient(113deg, #ff002a 0%, #720005 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.container > img {
  width: 20%;
}

.sections,
.socials {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.sections > p {
  font-family: ZCOOL KuaiLe;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.20169rem; /* 150% */
  transition: color 0.3s ease;
}

.sections > p:hover {
  color: var(--red);
}

.socials {
  margin-bottom: 0px;
}

.socials *,
.sections * {
  cursor: pointer;
}

.sections > div {
  font-weight: 600;
  transition: color 0.3s ease;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.sections option {
  font-family: Poppins;
  border: none;
  background-color: var(--black);
}

@media (max-width: 768px) {
  .sections {
    width: 60%;
  }
}
