.container {
  background: #0a0a0a url('../../assets/images/tokenomics_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.tokenInfo > span{
  font-family: Shojumaru !important;
}

.container > h1{
  font-family: Shojumaru !important;
}

.wrapper > div:nth-child(1) {
  width: 30%;
  display: flex;
  align-items: normal;
}

.wrapper > div:nth-child(2) {
  width: 55%;
}

.wrapper img {
  width: 100%;
}

.tokenomics_mobile {
  display: none;
}

@media (max-width: 768px) {
  .tokenomics {
    display: none;
  }

  .tokenomics_mobile {
    display: block;
  }
}

/* @keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
} */
