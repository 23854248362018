.container {
  background: #0a0a0a;
}

.container > h1 {
  margin-left: 65%;
}

.faqs {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  width: 50%;
}

.container img {
  width: 100%;
  animation: zoomInOut 4s infinite;
}

.container > div > div:nth-child(1) {
  width: 35%;
}

@media (max-width: 768px) {
  .container > h1 {
    margin: auto;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
