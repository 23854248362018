.container {
  background: #0a0a0a url('../../assets/images/ido_bg.png');
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
}

.container button {
  cursor: pointer;
  transition: scale 0.3s ease;
}

.container button:hover {
  scale: 1.2;
}

.container > h1 {
  margin: auto;
  margin-bottom: 5rem;
  text-align: center;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.wrapper > div:nth-child(1) {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
}

.wrapper > div:nth-child(1) > h3 {
  text-align: center;
  font-family: ZCOOL KuaiLe;
  font-size: 1.3075rem;
  font-style: normal;
  font-weight: 400;
  line-height: 174.5%; /* 2.28156rem */
}

.wrapper input {
  font-family: ZCOOL KuaiLe;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wrapper button {
  font-family: ZCOOL KuaiLe;
  font-size: 0.93938rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tokenInfo + div > button:nth-child(1) {
  font-family: ZCOOL KuaiLe;
  font-size: 0.87969rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 2.5rem;
  border-radius: 1.01325rem;
  background: linear-gradient(125deg, #d51b46 11.92%, #ee6910 83.65%);
}

.wrapper h1 {
  font-size: 1.75rem !important;
}

.affiliate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.affiliate > img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

/* .affiliate > span {
  display: flex;
  justify-content: space-between;
  height: 3rem;
  padding: 0.2rem;
  width: 90%;
  border-radius: 1.25rem;
  border: 1px solid var(--red);
} */

/* .affiliate > span > input {
  width: 70%;
  outline: none;
  border: none;
  padding: 1rem 1.25rem;
  background-color: transparent;
} */

.affiliate > button {
  border-radius: 1.25rem;
  background-color: var(--red);
  padding: 0.75rem 0.95rem;
  border: none;
}

.tokenInfo {
  width: 80%;
  border: 1px solid var(--red);
  padding: 2rem;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  gap: 1.6rem;
  margin-top: 1rem;
  background: rgba(13, 13, 13, 0.45);
}

.tokenInfo > p {
  font-family: ZCOOL KuaiLe;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height:1;
  display: flex;
  justify-content: space-between;
}

.tokenInfo > span:nth-child(1) {
  background: var(--red);
  padding: 1rem 3rem;
  border-radius: 1.25rem;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-family: Shojumaru;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tokenInfo > p > span {
  color: var(--red);
}

.presale {
  width: 50%;
  border: 1px solid var(--red);
  padding: 4rem;
  padding-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1.25rem;
  background: rgba(13, 13, 13, 0.45);
}

.presale > p {
  font-family: ZCOOL KuaiLe;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.5rem;
}

.presale > p:nth-child(2) {
  font-size: 2rem !important;
}

.presale > h1 {
  font-size: 3rem !important;
  margin-bottom: 2rem;
}

.presale > input {
  margin: 2.5rem 0;
  padding: 1rem 1.25rem;
  width: 70%;
  border-radius: 1.25rem;
  border: 1px solid var(--red);
  background: transparent;
  outline: none;
}

.presale > progress::-webkit-progress-value {
  background-color: var(--red);
}
.presale > progress::-webkit-progress-bar {
  background-color: #a29f9f;
}

.presale > progress {
  border-radius: 1rem;
  overflow: hidden;
  width: 70%;
}

.progressLabel {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: 2rem;
}

.buttons > button {
  border-radius: 1.36994rem;
  background: linear-gradient(125deg, #d51b46 11.92%, #ee6910 83.65%);
  border: none;
  padding: 0.8rem 1.25rem;
  margin-top: 1rem;
  cursor: pointer;
}

.timer {
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  display: flex;
  height: 4rem;
  gap: 0.25rem;
}

.timer > p {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 0.20219rem;
  border: 0.86px solid #ed0137;
  background: #000;
  padding: 0.5rem 0.3rem;
}

.timer > .splitter {
  color: var(--red);
  border: none;
  background: none;
  padding: 0;
}

.timer > p {
  font-family: Roboto Mono;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0rem; /* 0% */
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 3rem;
  }

  .wrapper > div {
    width: 80% !important;
  }

  .presale {
    margin-top: 2rem;
  }
}
