.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  position: fixed;
  z-index: 10;
  transform: translate(-50%);
  left: 50%;
  top: 1rem;
  background-color: var(--black);
  padding: 0rem 2rem 0rem 2rem;
  border-radius: 1rem;
}

.container > ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.logo img {
  width: 5rem;
}

.logo {
  gap: 1rem;
}

.logo > li {
  text-align: center;
  font-family: ZCOOL KuaiLe;
  font-size: 2rem;
  font-weight: 400;
  line-height: normal;
}

.logo > li:nth-child(2) {
  display: flex;
  align-items: center;
}

.menu {
  gap: 1.25rem !important;
  border-radius: 1.25rem;
  padding: 1rem 1.5rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
}

.menu > li {
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu > li:hover,
.menu select:hover {
  color: var(--red);
}

.menu select {
  transition: color 0.3s ease;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  font-size: 1.25rem;
}

.menu option {
  font-family: ZCOOL KuaiLe !important;
  border: none;
  background-color: var(--black);
}

.connect button:nth-child(1) {
  font-family: Poppins;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: normal;
  height: 2.5rem;
  border-radius: 0.96519rem;
}

.mobile_icons {
  display: none;
}

@media (max-width: 768px) {
  .mobile_icons {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    height: 100vh;
    width: 60vw;
    position: fixed;
    font-size: 2rem;
    align-items: center;
    top: -1rem;
    left: -10%;
    background: var(--black);
    transition: transform 0.3s ease-in-out;
    transform: translatex(-100%);
    gap: 5rem !important;
    padding: 5rem;
  }

  .menu select {
    font-size: 2rem;
  }

  .open {
    display: flex;
    transform: translatex(0);
  }
}
