.faqBox {
  width: 100%;
  padding: 1rem;
  border-radius: 1.25rem;
  border: 1px solid var(--red);
  background: #0f0f0f;
}

.question {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  cursor: pointer;
}

.answer {
  animation: expandCollapse 0.3s ease-in-out;
  color: #fbf9f9;
  font-family: ZCOOL KuaiLe;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170.422%; /* 1.81075rem */
  border-radius: 1.25rem;
  background: rgba(238, 238, 238, 0.04);
  padding: 1rem;
}

.question p {
  /* font-family: Shojumaru !important; */
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.icon {
  margin-left: auto;
}

.heading {
  margin-left: 5rem;
}

@keyframes expandCollapse {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 1000px; /* Adjust the value to fit your content height */
    opacity: 1;
  }
}
